.login-wrapper {
  height: 100vh;
  background-color:#0E101C;

  .login-card{
    background-color:#16192B;
    border-radius: 10px;
    padding:24px;
    height:600px;
  }

}