.card{
  background-color:#16192A;
  margin-bottom:16px;
  padding: 15px 20px;
  border-radius:8px;

  p{
    color:#8A8EA4;
    margin: 0;
    font-size:14px;
  }
}